/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
const $ = require('jquery');
global.$ = global.jQuery = $;

import { Modal, Carousel } from 'bootstrap';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import Alert from 'bootstrap/js/dist/alert';

import 'aos/dist/aos.css';
import './styles/includes/css/cookieconsent.css';
import Lightbox from './styles/includes/js/lightbox';

import { tns } from '../node_modules/tiny-slider/src/tiny-slider';
import '../node_modules/tiny-slider/src/tiny-slider.scss';
import './styles/includes/js/init-tiny-slider';

setTimeout(() => {
    $('.lightbox.imgs a').each(function () {
        $(this).click(function () {
            const lightbox = new Lightbox($(this), Modal, Carousel);
            return false;
        });
    })
}, 1000);

import SubmitDevisForm from './styles/includes/js/submitDevisForm';
const sumbitDevisForm = new SubmitDevisForm('#devis-form');
let stepValideFields = {
    '1': {
        '#devis_typeclient': 'typeclient',
        '#devis_society': 'input',
        '#devis_lastname': 'input',
        '#devis_firstname': 'input',
        '#devis_eml': 'eml',
        '#devis_phone': 'inputInt',
    },
    '2': {
        '#devis_departcp': 'inputInt',
        '#devis_departville': 'input',
        '#devis_departpays': 'input',
    },
    '3': {
        '#devis_arriveecp': 'inputInt',
        '#devis_arriveeville': 'input',
        '#devis_arriveepays': 'input',
    },
    
    '4': {
        '#devis_typemachine': 'input',
        '#devis_longueur': 'input',
        '#devis_largeur': 'input',
        '#devis_hauteur': 'input',
        '#devis_poids': 'input',
        '#devis_image': 'uploadImage',
    },
    '5': {
        '#devis_typechargement': 'input', 
        '#devis_typedechargement': 'input',
        // '#devis_messagedechargement': 'textarea'
    },
    '6': {
        '#devis_dateStart': 'inputDate',
        '#devis_dateEnd': 'inputDate',
        // '#devis_messagedate': 'textarea',
    },
}

$('.tabs-container div').each(function(){
    $('div .btn-container .next').on('click', function(){
        let step = $(this).data('step');
        let arr = [];
        console.log(step);
        
        stepValideFields[(step - 1)] ? arr = stepValideFields[(step - 1)] : arr = false;
        
        sumbitDevisForm.slideTab(step, 1, arr);

        return false;
    })

    $('div .btn-container .previous').on('click', function(){
        let step = $(this).data('step');
        sumbitDevisForm.slideTab(step, -1);

        return false;
    })
});

$('#devis_typeclient').on('change', function(){
    console.log($(this).val());
    if ($(this).val() == 'Professionnel') {
        $('.field-container-society .form-floating').removeClass('d-none');
        $('.field-container-society .form-floating #devis_society').removeClass('no-validation');
    } else {
        $('.field-container-society .form-floating').addClass('d-none');
        $('.field-container-society .form-floating #devis_society').addClass('no-validation');
    }
})

$('#devis_moyenchargement').on('change', function(){
    console.log($(this).val());
    if ($(this).val() == 'oui') {
        $('.field-container-moyenchargement').removeClass('d-none');
        $('.field-container-moyenchargement #devis_typechargement').removeClass('no-validation');
    } else {
        $('.field-container-moyenchargement').addClass('d-none');
        $('.field-container-moyenchargement #devis_typechargement').addClass('no-validation');
    }
})

$('#devis_moyendechargement').on('change', function(){
    console.log($(this).val());
    if ($(this).val() == 'oui') {
        $('.field-container-moyendechargement').removeClass('d-none');
        $('.field-container-moyendechargement #devis_typedechargement').removeClass('no-validation');
    } else {
        $('.field-container-moyendechargement').addClass('d-none');
        $('.field-container-moyendechargement #devis_typedechargement').addClass('no-validation');
    }
})

// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.min.css';
// import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js';

// $(document).ready(function() {
//     // you may need to change this code if you are not using Bootstrap Datepicker
//     $('.js-datepicker').datepicker({
//         format: 'dd-mm-yyyy'
//     });
// });


import Popup from './styles/includes/js/popup';
const popup = new Popup(Modal)

import './styles/includes/js/stickymenu';

const simpleParallax = require('simple-parallax-js');
let image = document.getElementsByClassName('img-parallax');
new simpleParallax(image, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)',
    scale: 1.5,
    orientation: 'up right'
});

function get_query() {
    var url = document.location.href;
    var qs = url.split('\/');
    console.log(qs);
    return qs;
}
