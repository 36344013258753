class SubmitDevisForm {
    constructor(el) {
        this.el = $(el);
        this.message = {
            1: 'Vos coordonnées',
            2: 'Ville du départ du transport',
            3: 'Ville de l\'arrivée du transport',
            4: 'Machine à transporter',
            5: 'Moyen de chargement et de déchargement ',
            6: 'Période à laquelle vous souhaiteriez effectuer le transport'
        }
        let url = this.el.attr('action');

        let h = parseInt($('.tab-1 .row', this.el).height() + 75 );
        
        $('.tabs-container', this.el).css('height', ( h < 600 ? '600px' : h + 'px' ));
        
    }

    submitForm(){
        this.el.submit();
    }

    slideTab(step, slide, valideFields){
        let valLastName = this.valideTab(valideFields);
        
        if(!valLastName && step != 7 ){
            let h = parseInt($('.tab-' + step).height() + 5 );
            let delaySetTimeOut1 = 0;
            let delaySetTimeOut2 = 0;

            slide == 1 ? delaySetTimeOut1 = 600 : delaySetTimeOut1 = 1000;
            slide == 1 ? delaySetTimeOut2 = 1000 : delaySetTimeOut2 = 600;
            $('#devis-text p').fadeOut(500);
            setTimeout(() => {
                $('#devis-text p').text(this.message[step]).fadeIn();
            }, 500);

            $('.tab-' + step).removeClass('d-none');

            setTimeout(() => {
                $('.tabs-container', this.el).css('height', ( h < 600 ? '600px' : h + 'px' ));
            }, delaySetTimeOut1);

            setTimeout(() => {
                $('.tab-' + ( step - slide )).removeClass('slide-middle');
                if(slide == 1){
                    $('.tab-' + ( step - slide )).addClass('d-none')
                    $('.tab-' + ( step - slide )).removeClass('slide-right');
                    $('.tab-' + ( step - slide )).addClass('slide-left');
                }
                else{
                    $('.tab-' + ( step - slide )).addClass('d-none')
                    $('.tab-' + ( step - slide )).removeClass('slide-left');
                    $('.tab-' + ( step - slide )).addClass('slide-right');
                }

                $('.tab-' + step).removeClass('slide-left');
                $('.tab-' + step).removeClass('slide-right');
                $('.tab-' + step).addClass('slide-middle');

                $('.step-item-' + ( step - slide ) ).removeClass('active');
                $('.step-item-' + step ).addClass('active');
            }, delaySetTimeOut2);
        }
        else if(!valLastName && step == 7){
            this.submitForm();
        }
        
    }

    valideTab(fields){
        let arrError = [];
        let i = 0;

        for (let field in fields) {
            let error = false;
            if(fields[field] == 'input'){
                error = this.validInput(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'inputInt'){
                error = this.validInputInt(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'textarea'){
                error = this.validTextarea(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'typeclient'){
                error = this.validTypeClient(field) 
                arrError[i] = error;
            }
            else if(fields[field] == 'eml'){
                error = this.validEmail(field)
                arrError[i] = error;
            }
            else if(fields[field] == 'inputDate'){
                error = this.validDate(field)
                arrError[i] = error;
            }
            else if(fields[field] == 'uploadImage'){
                error = this.validImage(field)
                arrError[i] = error;
            }
            i ++;
        }

        for (let err in arrError) {
            if(arrError[err]){
                return true;
            }
        }

        return false;
    }

    validInput(input){
        let error = false;
        let el = $(input);
        let elError = $(input + '_error');
        let fname = el.val();
        let textError = '';
        
        if (fname == '' && !el.hasClass('no-validation')) {
            textError += 'Vous devez remplir ce champ.<br>';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }
        console.log(fname.length);
        if (( (fname.length <= 1) || (fname.length > 255) )  && !el.hasClass('no-validation')) {
            textError += 'Le nombre de caratères doit être compris entre 2 et 255 caratères.<br>';
            el.addClass("box_error");
            error = true;
        }
        // if (!isNaN(fname) && !el.hasClass('no-validation')) {
        //     textError += 'Seuls les caratères sont autorisés.<br>';
        //     el.addClass("box_error");
        //     error = true;
        // }
        
        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }
        
        return error;
    }

    validInputInt(input){
        let error = false;
        let el = $(input);
        let elError = $(input + '_error');
        let fname = el.val();
        
        let textError = '';
        
        if (fname == '' && !el.hasClass('no-validation')) {
            textError += 'Vous devez remplir ce champ.<br>';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }
        if (isNaN(fname) && !el.hasClass('no-validation')) {
            textError += 'Seuls les chiffres sont autorisés (ex. : 45 ou 35.80).<br>';
            el.addClass("box_error");
            error = true;
        }
        
        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }
        
        return error;
    }

    validTextarea(choice){
        let error = false;
        let el = $(choice);
        let elError = $(choice + '_error');
        let fname = el.val();
        let textError = '';
        
        if (fname == '' && !el.hasClass('no-validation')) {
            textError += 'Vous devez remplir ce champ.';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }

        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }

        return error;
    }

    validTypeClient(choice){
        let error = false;
        let el = $(choice);
        let elError = $(choice + '_error');
        let fname = el.val();
        let textError = '';
        
        if (fname == 'none') {
            textError += 'Indiquez si vous êtes un particulier ou un professionnel';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }

        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }

        return error;
    }

    validEmail(email) {
        let error = false;
        let el = $(email);
        let elError = $(email + '_error');
        let fname = el.val();
        let textError = '';
        
        let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!regex.test(fname)) {
            textError = 'L\'e-mail n\'est pas valide';
            el.addClass("box_error");
            error = true;
        }else{
            textError = '';
            el.removeClass("box_error");
            error = false;
        }

        elError.html(textError);

        return error;
    }

    validDate(date) {
        var bits = date.split('/');
        var d = new Date(bits[2] + '/' + bits[1] + '/' + bits[0]);
        return !!(d && (d.getMonth() + 1) == bits[1] && d.getDate() == Number(bits[0]));
    }

    validImage(image) {
        let error = false;
        let el = $(image);
        let elError = $(image + '_error');
        let textError = '';
        
        let ext = el.val().split('.').pop().toLowerCase();
        console.log(ext);
        if(ext){
            if ($.inArray(ext, ['jpg','jpeg']) == -1){
                textError = 'Le format de l\'image n\'est pas valide. Le format accepté : jpg, jpeg';
                error = true;
            }else{
                var picsize = (el[0].files[0].size);
                console.log('Size ' + picsize + el[0]);
                if (picsize > 5120000){
                    textError = 'L\'image est trop lourde, supérieure à 5Mo';
                    error = true;
                }
            }
        }

        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }

        return error;
    }

}

export default SubmitDevisForm;